export function FacilityStockStatusDM (data) {
    data.forEach(element => {
        if(element.mos < 0) element.mos = 0;
        if(element.amc < 0) element.amc = 0;
        if(element.storeSoh < 0) element.storeSoh = 0;
        element.CalculatedStatus = getStatusFacility(element.mos);
    });
}

export function ProcurementStatusDM (data) {
    data.forEach(element => {
        if(element.Status.includes("Partially Received")) 
            element.CalculatedStatus = "Partially Received";
        else if(element.Status.includes("On Shipment")) 
            element.CalculatedStatus = "On Shipment";
        else if(element.Status.includes("On Tender")) 
            element.CalculatedStatus = "On Tender";
        else if(element.Status.includes("On Request")) 
            element.CalculatedStatus = "On Request";
        else if(element.Status.includes("On PurchaseOrder Process")) 
            element.CalculatedStatus = "On Purchase Order";
        else
            element.CalculatedStatus = element.Status;
    });
}

export function EpssStockStatusDM (data) {
    data.forEach(element => {
        if(element.MOS < 0) element.MOS = 0;
        if(element.AMC < 0) element.AMC = 0;
        if(element.SOH < 0) element.SOH = 0;
    });
}

export function NearExpiryDM (data) {
    data.forEach(element => {
        // if(element.SOHAmount < 0) element.SOHAmount = 0;
        // if(element.L6MonthAmount < 0) element.L6MonthAmount = 0;
        // if(element.NearExpiryPercentage < 0) element.NearExpiryPercentage = 0;
    });
}

function getStatusFacility(MOS) {
    if(MOS == 0) return "Stocked Out";
    else if(MOS < 1) return "Below EOP";
    else if (MOS < 2) return "Below Min";
    else if (MOS < 4) return "Normal";
    else if (MOS >= 4) return "Excess";
  
    return "";
}

export function getUsersByType (users) {
    var users = users.filter(function(user) {
        return user.JobTitle == "FMOH Managers "
    })
    return users;
}

export function customizeUsers (users) {
    var users = users.filter(function(user) {
        user.FullName = user.FirstName + user.LastName;
    })
}

export function GetChartDataDefault (arrayOfObjects, property) {
    const result = [];
    const totalCount = arrayOfObjects.length;

    arrayOfObjects.forEach(obj => {
        const label = obj[property];

        if (label !== undefined) {
        const existingItem = result.find(item => item.label === label);

        if (existingItem) {
            existingItem.value += 1;
            existingItem.Percentage = (existingItem.value / totalCount) * 100;
        } 
        else {
            result.push({
                label,
                value: 1,
                Percentage: (1 / totalCount) * 100,
            });
        }
        }
    });

    return result;
}

export function MedicalDeviceStatusProcess(data) {
    data.forEach(row => {
        row.mdmc = Math.floor(Math.random() * 2);
        row.memis = Math.floor(Math.random() * 2);
        row.maintenance = Math.floor(Math.random() * 2);
        row.preventive = (50 + Math.floor(Math.random() * 50)) + " %";
        row.functionality = (50 + Math.floor(Math.random() * 50)) + " %";
        row.installation = (50 + Math.floor(Math.random() * 50)) + " %";
        row.workOrderRate = (50 + Math.floor(Math.random() * 50)) + " %";
    });
}

const DataManipulationHelper = [
  
]

export default DataManipulationHelper;
