import moment 
  from "moment";

export function processNewUserData (users) {
    users.forEach(user => {
        user.Password = "Pass@" + user.UserName;
        preSaveDataProcessUser(user);
    });
}

export function preSaveDataProcessUser(user) {
    user.IsActive = user.IsActive !== undefined ? user.IsActive : true;
    user.UserTypeIDs = [];
    if(!user.UserTypes) user.UserTypes = [];
    user.UserTypes.forEach(userType => {
        user.UserTypeIDs.push(userType.value);
    });
    user.RegionIDs = [];

    if(!user.Regions) user.Regions = [];
    user.Regions.forEach(region => {
        user.RegionIDs.push(region.value);
    });
}

export function RecentlySyncedDataFilter(data) {
    data = data.filter(row => { 
        return moment(row.lastSyncDate) > moment().subtract(90, "days");
    });

    data.forEach(element => {
        if(moment(element.lastSyncDate) > moment().subtract(10, "days")) 
            element.CalculatedStatus = "Recent";
        else if(moment(element.lastSyncDate) > moment().subtract(20, "days")) 
            element.CalculatedStatus = "Normal";
        else
            element.CalculatedStatus = "Out Dated";
    });
    console.log(data);

    return data;
}

const DataServiceHelper = [
  
]

export default DataServiceHelper;