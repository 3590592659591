import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import {
  getRegisteredProductsEris,
  getLicencedFacilitiesEris,
  getImportedProductsProportion,
  getImportedAmount,
  importedAmountFormData
} from "../../../Services/dashboardService";
import { Formatter } from "../../../shared/Helpers/FormatHelper";
import DonoughtChart from "../../../shared/Charts/Donought";
import CustomKpiChart from "../../../shared/Charts/CustomKpiChart";
import CustomCard from "../../../shared/Charts/CustomCard";
import CustomBarChart from "../../../shared/Charts/CustomBarChart";

// helpers
import { DefaultChartDataFormatter } from "../../../shared/Helpers/ChartDataHelper";

// icons
import { TbChecklist } from "react-icons/tb";

import { GiCargoShip } from "react-icons/gi";
import { IMPORTED_AMOUNT } from "../../../shared/Charts/Constants/CustomCardConstants";

function ERIS() {
  const [registeredProducts, setRegisteredProducts] = useState([]);
  const [importedTrend, setImportedTrend] = useState(null);
  const [productProportion, setProductProportion] = useState(0);

  const [topImportedProducts, setTopImportedProducts] = useState([]);
  const [medicine, setMedicine] = useState(null);
  const kpiApi = "https://api.eris.efda.gov.et/api/Public/Report/Tabular/";

  function percentage(partialValue, totalValue) {
    return ((100 * partialValue) / totalValue).toFixed(1) + "%";
  }
  useEffect(() => {
    getRegisteredProductsEris("/96").then((data) => {
      let foodCount = 0;
      let medicineCount = 0;
      let deviceCount = 0;
      for (let entry of data.data.data) {
        if (entry.product_type === "Food") {
          foodCount += entry.count;
        }
        if (entry.product_type === "Medicine") {
          medicineCount += entry.count;
        }
        if (entry.product_type === "Medical Device") {
          deviceCount += entry.count;
        }
      }
      let total = foodCount + medicineCount + deviceCount;
      setRegisteredProducts([
        {
          product_type: "Food",
          count: foodCount,
          percentage: percentage(foodCount, total),
        },
        {
          product_type: "Medicine",
          count: medicineCount,
          percentage: percentage(medicineCount, total),
        },
        {
          product_type: "Medical Device",
          count: deviceCount,
          percentage: percentage(deviceCount, total),
        },
      ]);
    });
    getImportedAmount().then((data) => {
      let response = data.data.data;
      for (let res of response) {
        if (res.name === 2023) {
          // console.log(res);
          setImportedTrend(Formatter(res.value));
        }
      }
    });

    getLicencedFacilitiesEris().then((data) => {
      // let res = data.data.TotalRows;
      // let medicineCount = 0;
      // let deviceCount = 0;
      // for (let products of res) {
      //   if (products.product_type === "Medicine") {
      //     medicineCount++;
      //   } else if (products.product_type === "Medical Device") {
      //     deviceCount++;
      //   }
      // }
      setMedicine(data.data.TotalRows.toLocaleString());
      //setMedicalDevice(deviceCount.toLocaleString());
      getImportedProductsProportion("/97").then((data) => {
        setProductProportion(data.data.data);
      });
    });
  }, []);

  useEffect(() => {
    let sorted = [];
    productProportion && productProportion.sort((a, b) => b.price - a.price);
    for (let i = 0; i < 10; i++) {
      sorted.push(productProportion[i]);
    }
    setTopImportedProducts(sorted);
  }, [productProportion]);

  return (
    <Wrapper>
      <div className="container-context">
        <div className="main-body">
          <div className="main-body-container">
            <div className="section-main">
              <div className="flex-column section-main-container">
                <div className="section-body">
                  <div className="flex-row flex-wrap">
                    <DonoughtChart
                      data={registeredProducts}
                      label={["Registered products"]}
                      fields={["count", "product_type"]}
                      style={{
                        minWidth: "23rem",
                        margin: "1rem 0"
                      }}
                      chart_options={{
                        title: "Registered Products",
                        showExpandAlt: true,
                        showViewMore: true,
                      }}
                      hasTableView={true}
                    />
                    
                    <div>
                      <div className="flex-column">
                        <div>
                          <div className="flex-row flex-wrap">
                            <CustomCard
                              widget_config={{                  
                                page: "efda",
                                title: "Licensed Facilities",
                                description: "Number of Licensed Facilities",
                                value: medicine,
                                accent_color: "#1ab394",
                                link: "/Regulatory/LicencedFacilities",
                              }}
                              Icon={TbChecklist}
                            />
                            <CustomCard
                              widget_config={{                  
                                page: "efda",
                                title: "Imported Amount(2023)",
                                description: "Total worth of Imported Products",
                                value: importedTrend ? "$" + importedTrend : undefined,
                                accent_color: "#7b519d",
                                link: "/Regulatory/ImportedProducts",
                                modal_config: {
                                  title: "Total Imported Amount by Year",
                                  grid_config: {
                                    columns: IMPORTED_AMOUNT,
                                    data_source_api: "https://api.eris.efda.gov.et/api/Public/Report/Tabular/109",
                                    data_source: "directUrl",
                                    data_property: "data",
                                    method: "POST",
                                    api_data: importedAmountFormData(),
                                    select_filter_config: [],
                                    defaultFilterParams: {},
                                    density: "compact",
                                    modal_view: true
                      }
                                }
                              }}
                              Icon={GiCargoShip}
                            />
                          </div>
                        </div>
                        
                        <CustomKpiChart
                          kpiConfig={{                  
                            page: "efda",
                            title: "Average Product Registration processing time",
                            kpi_api: kpiApi,
                          }}
                        />
                      </div>
                    </div>
                </div>
                </div>
              </div>
            </div>
            <div>
              <CustomBarChart
                data={topImportedProducts}
                label={["Top 10 Imported products by Price"]}
                fields={["price", "item"]}
                style={{
                  margin: "auto",
                }}
                widget_config={{
                  title: "Top 10 Imported products by Price",
                  showExpandAlt: true,
                  axis: "y",
                  data_formatter: DefaultChartDataFormatter,
                  tabs: ["Chart view", "Table view"]
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto;

  .left-container {
    width: 100%;
    margin: 10px 10px 0px 10px;
    display: flex;
    margin: 0.5rem;
  }

  .card {
    display: flex;
    flex-grow: 1;
    min-width: 9.2rem;
    height: 7.5rem;
    margin: 0.5rem;
    background: white;
    box-shadow: 0px 1px 4px 0px grey;
    text-align: center;
  }
  .bar-chart {
    padding: 1rem;
  }
  .select-option {
    display: flex;
  }
  .select {
    width: 15rem;
  }
  @media screen and (max-width: 1025px) {
    .container-body {
      display: flex;
      flex-direction: column;
    }
  }
`;

export default ERIS;
