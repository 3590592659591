import React, { useRef, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { 
  Checkbox,
  FormControlLabel 
} from "@mui/material";

import { useSelector } from "react-redux";
import { selectors } from "../../../store";
import "./supplyChain.css";
import { IsEmpty } from "../../../shared/Helpers/FormatHelper";

import {
  STOCK_STATUS_FACILITY_COLUMNS,
  LEGEND_STOCK_STATUS_FACILITY,
  wastageRateColumn,
  daguNearExpiryColumn,
  CHART_CONFIG_STOCK_STATUS_FACILITY
} from "../../../shared/Charts/Constants/CustomDataGridConstants";
import KpiChartDagu from "../../../shared/Charts/KpiChartDagu";
import { FacilityStockStatusDM } from "../../../shared/Helpers/DataManipulationHelper";

import {
  DG_MODE_SELECT_CONFIG,
  DG_MODE_WITH_SN_SELECT_CONFIG,
  DG_REGION_ID_SELECT_CONFIG,
  DG_KPI_REGION_ID_SELECT_CONFIG,
  DG_FACILITY_ID_SELECT_CONFIG,
  DG_COMMODITY_TYPE_ID_SELECT_CONFIG,
} from "../../../shared/Inputs/SelectConstants";

import { lastSyncDate, ScrollToElement } from "../../../shared/Helpers/UIHelper";
import CustomWidget from "../../../shared/Charts/CustomWidget";
import CustomSelect from "../../../shared/Inputs/CustomSelect";
import CustomDataGrid from "../../../shared/Charts/CustomDataGrid";
import styled from "styled-components";

function SupplyChainFacility() {
  const params = useParams();

  const PAGE = "supply_chain_facility";
  localStorage.setItem("showFederalOnly", "");
  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const app = useSelector(selectors.app);
  const productAvailablityRef = useRef(null);
  const stockedAccordingToPlanRef = useRef(null);
  const stockStatusRef = useRef(null);
  const wastageRateRef = useRef(null);
  const nearExpiryRateRef = useRef(null);
  const sohFacilityApi = "/StockStatus/GetStockStatus?";
  let pageFilterParams = {};
  let filterParams = {};
  let filterParamsID = {};

  let facilityRegionSelectConfig = DG_REGION_ID_SELECT_CONFIG;
  let facilityRegionKPISelectConfig = DG_KPI_REGION_ID_SELECT_CONFIG;

  if(!IsEmpty(userInfo?.Regions)) {
    facilityRegionSelectConfig.place_holder = userInfo?.Regions[0].Name;
    facilityRegionKPISelectConfig.place_holder = userInfo?.Regions[0].Name;

    // set default filter params
    filterParams = { regionSn: userInfo?.Regions[0].SN };
    filterParamsID = { regionId: userInfo?.Regions[0].DaguID };
  }

  filterParamsID.syncDate = lastSyncDate(true);
  filterParamsID.filterItems = true;
  filterParams.filterItems = true;

  const selects = [
    DG_MODE_WITH_SN_SELECT_CONFIG,
    facilityRegionKPISelectConfig,
    DG_COMMODITY_TYPE_ID_SELECT_CONFIG,
  ];

  const kpiApi1 = "/Dashboard/GetWastageRateNationalDashboard";
  const kpiApi2 = "/Dashboard/GetNearExpiryNationalDashboard";
  const kpiApi3 = "/Dashboard/GetStockStatusNationalDashboard";
  const WastageRateGridApi = "/EHPMD_WebApi/DaguWastageRateRawData";
  const NearExpiryRateGridAPI = "/EHPMD_WebApi/DaguNearExpiryRawData";

  const kpiApi4 = "/KPI/GetWastageRate?";

  const handle_change = (param) => {
    pageFilterParams[param.paramName] = param.paramValue;
    productAvailablityRef.current.reload(pageFilterParams);
    // model19CollectionRef.current.reload(pageFilterParams);
    stockedAccordingToPlanRef.current.reload(pageFilterParams);
    wastageRateRef.current.reload(pageFilterParams);
    nearExpiryRateRef.current.reload(pageFilterParams);
  };

  useEffect(() => {
    // Will scroll to the given section_id if provided
    ScrollToElement(document.getElementById(params.section_id));
  }, []);

  return (
    <Wrapper>
      
      <div className="container-context">       
        <div className="main-body">
          <div className="main-body-container">
            <div className="section-main">
              <div className="flex-column section-main-container">
                <div className="section-header">
                  <div className="section-header-container">
                    <p className="section-header-title">KPI</p>
                  </div>
                </div>
                <div className="widget-toolbar">
                  <div className="flex-row flex-wrap widget-toolbar-container">
                    {selects.map(function (config) {
                      return (
                        <div className="toolbar-item">
                          <CustomSelect
                            handle_change={handle_change}
                            component_config={config}
                          />
                        </div>
                      );
                    })}
                  </div>
                  <div className="flex-row widget-toolbar-container">
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="IsTracer"
                            onChange={ (e, value) => {
                              handle_change({ paramName: "filterItems", paramValue: value })
                            }}
                            defaultChecked={ true }
                          />
                        }
                        label={ "Show Tracer Items" }
                      />
                  </div>
                </div>
                <div className="section-body">
                  <div className="flex-row flex-wrap section-body-container">
                    <KpiChartDagu
                      ref={wastageRateRef}
                      kpiConfig={{
                        page: PAGE,
                        title: "Wastage Rate",
                        kpi_api: kpiApi1,
                        convert_params_to_formData: true,
                        default_filter_params: filterParams,
                        dataSource: "dagu",
                        method: "get",
                        operationType: "Average",
                        field: "wastageRate",
                        unit: "%",
                        rowDataApi: WastageRateGridApi,
                        columnDef: wastageRateColumn,
                        tabs: ["Chart", "Tabular"],
                        rowDataSpecified: true,
                        target: 2,
                        kpiId: 20,
                      }}
                    />
                    {/* <CustomWidget
                      ref={nearExpiryRateRef}
                      widgetConfig={{
                        page: PAGE,
                        title: "Near Expiry Total",
                        data_source_api: kpiApi2,
                        source: "dagu",
                        method: "get",
                        operationType: "Total",
                        default_filter_params: filterParams,
                        gridApi: NearExpiryRateGridAPI,
                        columnDef: daguNearExpiryColumn,
                        filterConfig: [],
                      }}
                    /> */}
                    <KpiChartDagu
                      ref={productAvailablityRef}
                      kpiConfig={{
                        page: PAGE,
                        title: "Product Availability",
                        kpi_api: kpiApi3,
                        convert_params_to_formData: true,
                        default_filter_params: filterParams,
                        dataSource: "dagu",
                        method: "get",
                        operationType: "Aggregate Percentile",
                        field: "",
                        unit: "%",
                        rowDataApi: "",
                        columnDef: "",
                        tabs: ["Chart", "Tabular"],
                        rowDataSpecified: false,
                        target: 95,
                        kpiId: 21,
                      }}
                    />
                    <KpiChartDagu
                      ref={stockedAccordingToPlanRef}
                      kpiConfig={{
                        page: PAGE,
                        title: "Stocked according to plan",
                        kpi_api: kpiApi3,
                        convert_params_to_formData: true,
                        default_filter_params: filterParams,
                        dataSource: "dagu",
                        method: "get",
                        operationType: "mos Aggregate Percentile",
                        field: "",
                        unit: "%",
                        rowDataApi: "",
                        columnDef: "",
                        tabs: ["Chart", "Tabular"],
                        rowDataSpecified: false,
                        target: 60,
                        kpiId: 22,
                      }}
                    />
                  </div>
                </div>
              </div>

              <div id="facility-stock-status-section" className="flex-column section-main-container">
                <div className="section-header">
                  <div className="section-header-container">
                    <p className="section-header-title">Stock Status</p>
                  </div>
                </div>
                <div className="section-body">
                  <div className="section-widgets-container section-widgets-container-data-grid">
                    <CustomDataGrid
                      component_config={{
                        page: PAGE,
                        title: "Facility Stock Status",
                        columns: STOCK_STATUS_FACILITY_COLUMNS,
                        data_source_api: sohFacilityApi,
                        data_source: "dagu",
                        data_manipulator: FacilityStockStatusDM,
                        convert_params_to_formData: true,
                        data_property: "model",
                        method: "POST",
                        select_filter_config: [
                          DG_MODE_SELECT_CONFIG,
                          facilityRegionSelectConfig,
                          DG_FACILITY_ID_SELECT_CONFIG,
                        ],
                        checkbox_filter_config: [
                          { 
                            param_name: "syncDate", 
                            label: "Display recently synchronized facilities",
                            default_checked: true,
                            value_function: lastSyncDate,
                            title: "Display stock from synchronized facilities within the past 30 days."
                          },
                          { 
                            param_name: "filterItems", 
                            label: "Show Tracer Items",
                            default_checked: true
                          }
                        ],                          
                        defaultFilterParams: filterParamsID,
                        use_default_filter_params: true,
                        legend: LEGEND_STOCK_STATUS_FACILITY
                      }}
                      chart_config={CHART_CONFIG_STOCK_STATUS_FACILITY}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
const Wrapper = styled.div`
  width: 100%;
  padding-right: 0;
  padding-left: 0;
  margin-right: auto;
  margin-left: auto;

  @media screen and (max-width: 1025px) {
    .ain-body-container {
    display: flex;
    flex-direction: column;
  }
`;
export default SupplyChainFacility;
